<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.auditProgram.label' | translate: 'Audit Program'"
        [placeholder]="moduleFieldString + '.auditProgram.placeholder' | translate: 'Search Audit Program'"
        [control]="formGroup?.controls?.auditProgram"
        [viewMode]="'create'"
        [inlineMode]="false"
        [appendTo]="'body'"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="undefined"
        [customProjectionFields]="null"
        [targetTypes]="['AUDIT_PROGRAM']"
        [searchUrl]="searchUrl"
        [dataKey]="'code'"
      >
      </app-target-code-selector>
    </div>

    <div class="col-12">
      <app-text-editor
        [height]="'100px'"
        [name]="'textarea'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <ng-content></ng-content>
</form>
