import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuditProgramReportDto, BaseForm } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { AuditProgramDataService } from '../../services/data/audit-program-data.service';
import { AuditProgramReportDataService } from '../../services/data/audit-program-report-data.service';

@Component({
  selector: 'app-audit-program-report-item-form',
  templateUrl: './audit-program-report-item-form.component.html',
  styleUrls: ['./audit-program-report-item-form.component.scss'],
})
export class AuditProgramReportItemFormComponent extends BaseForm<AuditProgramReportDto> implements OnInit {
  contextVariables: any[] = [];
  searchUrl;
  constructor(
    public viewModeService: ViewModeService,
    private requestService: AuditProgramReportDataService,
    private programRequestService: AuditProgramDataService
  ) {
    super(viewModeService, 'AUDIT_PROGRAM_REPORT');
    this.searchUrl = programRequestService.myProgramManagerSearchUrl;
  }
  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });

    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      auditProgram: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }

  ngAfterViewInit(): void {
    this.formStatusChanged.emit(this.formGroup.valid ? 'VALID' : 'INVALID');
    this._setupSubscriptions();
  }
}
